const Sdata = {
  shopItems: [
    {
      "id": 1,
      "discount": 12,
      "cover": "./images/flash/apples.jpg",
      "name": "Organic Apples",
      "price": 3.5
    },
    {
      "id": 2,
      "discount": 18,
      "cover": "./images/flash/vegetables.jpg",
      "name": "Frozen Mixed Vegetables",
      "price": 5.5
    },
    {
      "id": 3,
      "discount": 22,
      "cover": "./images/flash/beef.png",
      "name": "Grass-Fed Beef",
      "price": 13
    },
    {
      "id": 4,
      "discount": 8,
      "cover": "./images/flash/milk.png",
      "name": "Low-Fat Milk",
      "price": 2.3
    },
    {
      "id": 5,
      "discount": 28,
      "cover": "./images/flash/bread.png",
      "name": "Whole Wheat Bread",
      "price": 1.8
    },
    {
      "id": 6,
      "discount": 35,
      "cover": "./images/flash/beef.png",
      "name": "Wild-Caught Salmon",
      "price": 16
    },
    {
      "id": 7,
      "discount": 10,
      "cover": "./images/flash/apples.jpg",
      "name": "Red Apples",
      "price": 3
    },
    {
      "id": 8,
      "discount": 20,
      "cover": "./images/flash/vegetables.jpg",
      "name": "Fresh Mixed Vegetables",
      "price": 6
    },
    {
      "id": 9,
      "discount": 25,
      "cover": "./images/flash/milk.png",
      "name": "Almond Milk",
      "price": 2.8
    },
    {
      "id": 10,
      "discount": 15,
      "cover": "./images/flash/bread.png",
      "name": "Gluten-Free Bread",
      "price": 2
    },
    {
      "id": 11,
      "discount": 18,
      "cover": "./images/flash/beef.png",
      "name": "Beef Ribeye",
      "price": 14
    },
    {
      "id": 12,
      "discount": 30,
      "cover": "./images/flash/beef.png",
      "name": "Smoked Salmon",
      "price": 18
    },
    {
      "id": 13,
      "discount": 5,
      "cover": "./images/flash/milk.png",
      "name": "Whole Milk",
      "price": 2.5
    },
    {
      "id": 14,
      "discount": 27,
      "cover": "./images/flash/vegetables.jpg",
      "name": "Organic Mixed Vegetables",
      "price": 5.8
    },
    {
      "id": 15,
      "discount": 32,
      "cover": "./images/flash/beef.png",
      "name": "Salmon Fillet",
    }]  
}
export default Sdata
