const Data = {
  productItems:  [
    {
      id: 1,
      discount: 10,
      cover: "./images/flash/apples.jpg",
      name: "Apples",
      price: 3,
    },
    {
      id: 2,
      discount: 15,
      cover: "./images/flash/vegetables.jpg",
      name: "Mixed Vegetables",
      price: 5,
    },
    {
      id: 3,
      discount: 20,
      cover: "./images/flash/beef.png",
      name: "Beef",
      price: 12,
    },
    {
      id: 4,
      discount: 5,
      cover: "./images/flash/milk.png",
      name: "Milk",
      price: 2,
    },
    {
      id: 5,
      discount: 25,
      cover: "/images/flash/bread.png",
      name: "Bread",
      price: 1.5,
    },
    {
      id: 6,
      discount: 30,
      cover: "./images/flash/beef.png",
      name: "Salmon",
      price: 15,
    },
  ]}
export default Data
